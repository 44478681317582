import React, { useState, createRef } from 'react';
import { useTranslation } from 'react-i18next';

import { Box, Tooltip } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import UnCheckIcon from '@material-ui/icons/CheckBoxOutlineBlankOutlined';
import CheckIcon from '@material-ui/icons/CheckBoxOutlined';
import { Album, BurstMode, FolderSharedOutlined, OpenInNew, Person } from '@material-ui/icons';
import Collapse from '@material-ui/core/Collapse';
import Zoom from '@material-ui/core/Zoom';
import LoadMore from '../components/Pagination/LoadMore';
import { useStyles } from './styles/tableCollapse';
import { HeaderFields, ItemFields, ItemFieldsResponsive } from './tableCollapseItems';
import { useWindowSize } from '../utils/useWindowsSize';
import { GetItemType } from '../utils/functions';

const WIDTHMAX = 960;

const EntityIcon = ({ type }) => {
  switch (type) {
    case 'Patient':
      return <Person />;
    case 'MedicalCase':
      return <FolderSharedOutlined />;
    case 'DicomStudy':
      return <BurstMode />;
    default:
      return <Album />;
  }
};

const TableCollapse = (props) => {
  const { t } = useTranslation();
  const { fieldNames, items, rowActionButton, GetTitle, GetSubtitle, GetCollapse, responsive, openTooltip } = props;
  const { selected, setSelected, onDoubleClick, handleGoto, isSearch } = props;
  const { isMore, fetchMoreFn, order, handleOrder } = props;

  const [width] = useWindowSize();
  const isResponsive = (responsive !== undefined) ? responsive : width >= WIDTHMAX;

  const classes = useStyles(isResponsive);
  const parentRef = createRef(null);
  const [posLeft, setPosLeft] = useState(0);
  const [timer, setTimer] = useState();

  const handleSelect = (id) => (evt) => {
    evt.stopPropagation();

    if (!timer) {
      if (setSelected) {
        if (selected.includes(id)) {
          setSelected(selected.filter((item) => item !== id));
        } else {
          setSelected([id]);
        }
      }
      setTimer(setTimeout(() => {
        setTimer();
      }, 250));
    } else {
      if (onDoubleClick) onDoubleClick(id);
      clearTimeout(timer);
      setTimer();
    }
  };

  const handleScroll = () => {
    if (-parentRef.current.scrollLeft !== posLeft) {
      setPosLeft(-parentRef.current.scrollLeft);
    }
  };

  const childrenProps = { classes, isResponsive, fieldNames, GetTitle, GetSubtitle, handleGoto, posLeft, order, handleOrder };
  const ActionButton = rowActionButton || {
    Icon: OpenInNew,
    tooltip: openTooltip || 'open',
    inProgress: null,
    disabled: false,
  };

  return (
    <div className={classes.table}>
      {!!isResponsive && <HeaderFields {...childrenProps} />}
      <div className={classes.tableItemsScroll} ref={parentRef} onScroll={handleScroll}>
        <div className={classes.tableItems}>

          {items && items.map((item, index) => {
            const type = GetItemType(item);
            return (
              <div key={index.toString()} className={selected.includes(index) ? classes.itemSelected : classes.item} onClick={handleSelect(index)} aria-hidden="true">
                <div className={classes.tableItem}>
                  {isSearch && type && <Box className={classes.entity}><EntityIcon type={type} /></Box>}
                  {!isSearch && selected.includes(index) && <CheckIcon className={classes.tableItemIcon} />}
                  {!isSearch && !selected.includes(index) && <UnCheckIcon className={classes.tableItemIcon} />}
                  <div style={{ display: 'flex', width: '100%' }}>
                    {isResponsive
                      ? <ItemFields {...childrenProps} index={index} item={item} />
                      : <ItemFieldsResponsive {...childrenProps} index={index} item={item} />}
                  </div>
                  {!!handleGoto
                && (
                  <div className={classes.goButtonBox}>
                    {!item.noAction ? (
                      <Tooltip title={t(ActionButton.tooltip)} TransitionComponent={Zoom} placement="bottom" arrow>
                        <IconButton disabled={ActionButton.inProgress === index} className={classes.goButton} onClick={(evt) => handleGoto(evt, index)}>
                          <ActionButton.Icon />
                        </IconButton>
                      </Tooltip>
                    ) : <div style={{ minHeight: 54 }}> </div>}
                  </div>
                )}
                </div>

                <Collapse className={classes.collapse} in={selected.includes(index)} timeout="auto" unmountOnExit>
                  <div style={{ display: 'flex' }}>
                    {GetCollapse && <GetCollapse item={item} fieldNames={fieldNames} /> }
                  </div>
                </Collapse>
              </div>
            );
          })}

          {isMore && <LoadMore container parent={parentRef} fetchMoreFn={fetchMoreFn} />}
        </div>
      </div>
    </div>
  );
};

export default TableCollapse;
