import React, { useState } from 'react';

import { useTranslation } from 'react-i18next';

import { useQuery } from '@apollo/react-hooks';
import { useSelector } from 'react-redux';
import { navigate } from 'gatsby';
import moment from 'moment-timezone';

import { Container, Menu, MenuItem } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/DeleteOutline';
import SortIcon from '@material-ui/icons/Sort';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import OpenInNewIcon from '@material-ui/icons/OpenInNewOutlined';
import { useKeycloak } from 'react-keycloak';
import { useMutation } from 'react-apollo';
import { toast } from 'react-toastify';
import { isEmpty } from '../../utils/ObjectUtils';
import { PageListContent } from '../Common/styled/PageContent';
import { SectionContainer } from '../Section/styled/StyledSection';
import Loading from '../Common/Loading';
import { GET_HOSPITAL_STUDIES_TO_LIST } from '../../graphql/queries';
import { SectionBar } from '../../componentsUI/SectionBar';
import TableCollapse from '../../componentsUI/tableCollapse';
import { DeleteStudyDialog } from '../Study/modal/DeleteStudyDialog';
import { DOWNLOAD_DICOM_STUDY } from '../../graphql/mutations';
import { DownloadDicomFile } from '../../utils/DicomFileUtils';
import { ScrollableContainer } from '../Common/ScrollableContainer';
import { Navbar } from '../Navbar/styled/NavbarStyles';
import { ContainerUI } from '../Common/styled/ContainerUI';
import { networkErrorParse } from '../../utils/ErrorGraphQLUtils';
import { AlertUI, AlertWrapperUI } from '../../componentsUI/Alert';

const fieldNames = [
  { label: 'study.title', id: 'node.title', field: 'TITLE', direction: 'ASC', width: 370 },
  { label: 'created.at', id: 'node.createdAt', field: 'CREATED_AT', direction: 'DESC', width: 120, format: 'DATEFORMAT' },
];

const sortData = {
  default: { field: 'TITLE', direction: 'ASC' },
};

export const StudiesView = () => {
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = useState(null);
  const openMenu = Boolean(anchorEl);
  const hospital = useSelector((state) => state.hospital);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [selected, setSelected] = useState([]);
  const [orderByField, setOrderByField] = useState(sortData.default.field);
  const [orderByDirection, setOrderByDirection] = useState(sortData.default.direction);
  const [keycloak] = useKeycloak();
  const [downloadDicomStudy, { loading: requesting }] = useMutation(DOWNLOAD_DICOM_STUDY);

  const { loading, data, error, refetch, fetchMore } = useQuery(
    GET_HOSPITAL_STUDIES_TO_LIST,
    {
      variables: {
        hospitalUuid: hospital.uuid,
        first: 50,
        orderBy: { field: orderByField, direction: orderByDirection },
      },
      fetchPolicy: 'cache-and-network',
      notifyOnNetworkStatusChange: true,
    },
  );

  const study = (data && data.hospitalStudies && selected.length > 0)
    && data.hospitalStudies.edges[selected[0]] && data.hospitalStudies.edges[selected[0]].node;

  const fetchMoreStudies = (fetchMoreCb) => {
    const { endCursor } = data.authorizedPatients.pageInfo;

    fetchMore({
      query: GET_HOSPITAL_STUDIES_TO_LIST,
      variables: {
        hospitalUuid: hospital.uuid,
        first: 50,
        cursor: endCursor,
        orderBy: { field: orderByField, direction: orderByDirection },
      },
      updateQuery: (previousResult, { fetchMoreResult }) => {
        const newEdges = fetchMoreResult.hospitalStudies.edges;

        return newEdges.length ? {
          hospitalStudies: {
            // eslint-disable-next-line no-underscore-dangle
            __typename: previousResult.hospitalStudies.__typename,
            totalCount: previousResult.hospitalStudies.totalCount,
            pageInfo: fetchMoreResult.hospitalStudies.pageInfo,
            edges: [...previousResult.hospitalStudies.edges, ...newEdges],
          },
        } : previousResult;
      },
    })
      .then(({ data: fetchMoreData }) => (
        fetchMoreData.hospitalStudies.pageInfo.hasNextPage && fetchMoreCb()
      ));
  };

  const reverseDirection = () => (
    orderByDirection === 'ASC' ? 'DESC' : 'ASC'
  );

  const handleOrderBy = (value) => {
    if (value.field) {
      if (value.field === orderByField) {
        setOrderByDirection(reverseDirection());
      } else {
        setOrderByDirection(value.direction);
      }

      setOrderByField(value.field);
      refetch();
      setAnchorEl();
      setSelected([]);
    }
  };

  const getTitle = (item) => (
    `${item.node.title}`
  );

  const getSubTitle = (item) => {
    const formattedDate = item.node.createdAt && moment(item.node.createdAt).utc().format('DD[/]MM[/]YYYY');
    const formattedModalities = item.node.modalities.join('/');

    return `${formattedDate} · ${item.node.numberOfSeries} Series · ${item.node.numberOfInstances} Images · ${formattedModalities}`;
  };

  const handleGoto = (evt, index) => {
    evt.stopPropagation();
    const indexStudy = data.hospitalStudies.edges[index].node;

    navigate(`/study/${indexStudy.uuid}`);
  };

  const handleOpen = (evt) => {
    evt.stopPropagation();
    navigate(`/study/${study.uuid}`);
  };

  const handleUpload = () => {
    navigate(`/study/upload/${hospital.uuid}?uth=1`);
  };

  const handleDownload = async () => {
    try {
      toast(t('requesting.study.download'), { className: 'toast-info' });
      await downloadDicomStudy({ variables: { uuid: study.uuid } });
      DownloadDicomFile(study, keycloak);
    } catch (errors) {
      if (errors.graphQLErrors && errors.graphQLErrors.length) toast(errors.graphQLErrors[0].message, { className: 'toast-error' });
    }
  };

  const handleCloseDelete = () => {
    setShowDeleteModal(false);
    setSelected([]);
  };

  const handleOrder = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const buttons = [
    { name: 'open', icon: OpenInNewIcon, handleClick: handleOpen, disabled: selected.length === 0 },
    { name: 'divider1', type: 'divider' },
    { name: 'upload.study', icon: CloudUploadIcon, handleClick: handleUpload, disabled: false },
    { name: 'download', icon: CloudDownloadIcon, handleClick: handleDownload, disabled: selected.length === 0 || requesting },
    { name: 'delete', icon: DeleteIcon, handleClick: () => setShowDeleteModal(true), disabled: selected.length === 0 },
    { name: 'divider2', type: 'divider' },
    { name: 'sort.by', icon: SortIcon, handleClick: handleOrder, disabled: false },
  ];

  const OrderIcon = ({ className, direction }) => (
    (direction === 'ASC') ? <ArrowDropDownIcon className={className} /> : <ArrowDropUpIcon className={className} />
  );

  const errorMessage = networkErrorParse(error);

  if (errorMessage) {
    return (
      <AlertWrapperUI>
        <AlertUI severity="error" title="Error">{t(errorMessage)}</AlertUI>
      </AlertWrapperUI>
    );
  }

  return (
    <ContainerUI>
      <Navbar>
        {!!study && <DeleteStudyDialog open={showDeleteModal} onClose={handleCloseDelete} study={study} refetch={refetch} />}
        <Menu anchorEl={anchorEl} open={openMenu} onClose={() => setAnchorEl(null)}>
          {fieldNames.filter((filter) => !!filter.field).map((item) => (
            <MenuItem
              key={item.id}
              onClick={() => handleOrderBy(item)}
              style={{ display: 'flex', alignItems: 'center' }}
            >
              <span style={{ width: 150 }}>{t(item.label)}</span>
              {(item.field === orderByField) && <OrderIcon direction={orderByDirection} />}
            </MenuItem>
          ))}
        </Menu>
        <SectionBar title="studies" items={buttons} />
      </Navbar>

      <Container maxWidth="lg">
        <ScrollableContainer>
          <PageListContent>
            {(loading && isEmpty(data)) ? <Loading /> : (
              <SectionContainer>
                <TableCollapse
                  responsive={false}
                  fieldNames={fieldNames}
                  items={data && data.hospitalStudies && data.hospitalStudies.edges}
                  GetTitle={getTitle}
                  GetSubtitle={getSubTitle}
                  selected={selected}
                  setSelected={setSelected}
                  handleGoto={handleGoto}
                  isMore={data
                    && data.hospitalStudies
                    && data.hospitalStudies.pageInfo
                    && data.hospitalStudies.pageInfo.hasNextPage}
                  fetchMoreFn={fetchMoreStudies}
                  order={{ field: orderByField, direction: orderByDirection }}
                  handleOrder={handleOrderBy}
                />
              </SectionContainer>
            )}
          </PageListContent>
        </ScrollableContainer>
      </Container>
    </ContainerUI>
  );
};
